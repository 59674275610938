import React from "react";
import { Home, Menu } from "./pages";
import cookie from "js-cookie";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

function App() {
  const token = cookie.get("token");
  return (
    <Router>
      <Switch>
        <Route path="/home" component={Menu} />
        <Route path="/menu" component={Menu} />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
