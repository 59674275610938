import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { NavButton } from "./";
import Theme from "./Theme.js";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";

const Menu = styled.ul`
  display: ${(props) => (props.open ? "block" : "none")};
  flex-direction: column;
  background-color: ${Theme.NAV_BAR_COLOR};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.NAV_TEXT_COLOR};
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  list-style: none;
  margin: ${(props) => props.margin || "10vh 0 0 0"};
  padding: 1vh 0 1vh 0;
  border: 0;
  font-size: 100%;
  width: 100%;
  font: inherit;
  vertical-align: baseline;
  z-index: 1;
  animation: ${(props) => props.animation};
  animation-delay: ${(props) => props.animationDelay};
  animation-fill-mode: both;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: ${(props) => props.color || Theme.NAV_TEXT_COLOR};
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: ${(props) => (props.mobile ? "1.5vh" : "1.3vh")};
  font-family: ${Theme.MAIN_FONT};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;

export default class Dropdown extends Component {
  constructor() {
    super();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClick.bind(this);
    this.state = {
      open: false,
    };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClick = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target))
      this.setState({ open: false });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }
  render() {
    const {
      label,
      options = [],
      containerStyle,
      mobile,
      hoverable,
    } = this.props;
    return (
      <div
        ref={this.setWrapperRef}
        style={
          containerStyle || {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "visible",
          }
        }
        onMouseLeave={() => hoverable && this.setState({ open: false })}
        onMouseEnter={() =>
          setTimeout(() => hoverable && this.setState({ open: true }))
        }
      >
        <NavButton
          animation={this.props.animation}
          disableHover={true}
          onClick={() =>
            this.setState(
              {
                open:
                  this.state.open && this.state.open !== "initialize"
                    ? false
                    : true,
              },
              console.log(this.state.open)
            )
          }
          mobile={mobile}
        >
          {label}
        </NavButton>
        <Menu
          open={this.state.open}
          animation={
            this.state.open
              ? css`.3s ${keyframes`${fadeInDown}`}`
              : css`.15s ${keyframes`${fadeOutUp}`}`
          }
          animationDelay=".05s"
        >
          {options.map((option, index) => (
            <Option
              key={option.label + index}
              mobile={mobile}
              onClick={() => this.props.history.push(option.route)}
            >
              {option.label}
            </Option>
          ))}
        </Menu>
      </div>
    );
  }
}
