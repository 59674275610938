import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  Column,
  Text,
  InfoBox,
  Row,
  Clickable,
  Button,
  Slideshow,
  Divider,
} from "../../globalComponents";
import Logo from "../../assets/logos/Main_Logo.png";
import Theme from "../../globalComponents/Theme.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

class Home extends React.Component {
  state = { open: false, services: null, site: [] };
  fetchFeed = () => {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/feed`, {
        params: {
          name: "Services",
          company: process.env.REACT_APP_COMPANY_NAME,
        },
      })
      .then((res) => {
        if (res.data.success)
          this.setState({ services: res.data.feed.entries }, this.fetchSite);
        else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchFeed, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };
  fetchSite = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/site`, {
        params: {
          company: process.env.REACT_APP_COMPANY_NAME,
          name: "thehoneydoboys.com",
        },
      })
      .then((res) => {
        if (res.data.success)
          this.setState({ site: res.data.site, loading: false });
        else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchSite, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  getEmail(name) {
    const emails = this.state.site.emails;
    if (emails)
      for (var i = 0; i < emails.length; i++) {
        if (emails[i].name === name) return emails[i].value;
      }
    else return "";
  }

  getInfo(name) {
    const info = this.state.site.info;
    if (info)
      for (var i = 0; i < info.length; i++) {
        if (info[i].name === name) {
          console.log(info[i].value);
          return info[i].value;
        }
      }
    else return "";
  }

  componentDidMount() {
    this.fetchFeed();
  }
  render() {
    const { open, services, loading } = this.state;
    const mobile = this.props.windowWidth < 800;
    console.log("feed: ", services);
    return (
      <NavBar history={this.props.history}>
        <Section backgroundColor={Theme.MAIN_COLOR} height="80vh">
          <Text
            color="white"
            size={mobile ? "8vw" : "4vw"}
            margin="1.5vh auto 0 auto"
            style={{ left: 0, right: 0 }}
          >
            {this.getInfo("title") || !process.env.REACT_APP_BRAND
              ? "A Sweet Experience Every Time"
              : "Frozen Yogurt | Ice Cream | Baked Goods "}
          </Text>
          <Text color="white" size={mobile ? "4vw" : "2vw"}>
            {this.getInfo("subtitle")}
          </Text>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);
