import React, { Component, Window } from "react";
import styled, { keyframes, css } from "styled-components";
import Circular from "../assets/logos/Secondary_Logo.png";
import {
  Dropdown,
  NavButton,
  Text,
  Column,
  Clickable,
  Row,
  ProfileButton,
} from "./";
import Theme from "./Theme.js";
import { fadeInDown, rollIn } from "react-animations";
import Page_Background from "../assets/drone_flyover.mp4";
import cookie from "js-cookie";
import windowSize from "react-window-size";
import Logo from "../assets/logos/Main_Logo.png";
import Secondary_Logo from "../assets/logos/Secondary_Logo.png";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const routes = [
  {
    label: "Menu",
    route: "/menu",
  },
  // {
  //   label: "Locations",
  //   route: "menu",
  //   menu: [{ label: "Mt. Juliet", route: "locations/mj" }],
  // },
];

class NavBar extends Component {
  state = {
    initialize: this.props.history.location.pathname === "/home",
  };
  render() {
    const { initialize } = this.state;
    const mobile = this.props.windowWidth < 600;
    const tiny = this.props.windowWidth < 400;
    if (initialize)
      setTimeout(() => this.setState({ initialize: false }), 1500);
    return (
      <div style={{ maxWidth: "100vw" }}>
        <div
          style={{
            display: "flex",
            height: mobile ? "12vh" : "10vh",
            flexDirection: "row",
            width: "99vw",

            backgroundColor: Theme.NAV_BAR_COLOR,
            boxShadow: `0 0 10px ${Theme.NAV_TEXT_COLOR}`,
            zIndex: 90,
            position: "fixed",
          }}
        >
          <Clickable
            disableHover={true}
            animation={
              initialize &&
              css` 1s
                ${keyframes`${fadeInDown}`}
              `
            }
            onClick={() => this.props.history.push("/")}
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              margin: "auto",
              minHeight: "8vh",
              width: "auto",
              zIndex: 99,
            }}
          >
            <img
              src={process.env.REACT_APP_BRAND ? Secondary_Logo : Logo}
              style={{
                maxWidth: mobile ? "180px" : "250px",
                height: "auto",
                display: "flex",
                margin: "auto",
              }}
            />
          </Clickable>
          <Row margin="auto auto 0 2%" width="20vw">
            <a
              href="https://www.facebook.com/Sweet-Ceces-Mt-Juliet-TN-374130459801388"
              target="_blank"
              style={{ margin: "auto 2% auto 0" }}
            >
              <FontAwesomeIcon
                style={{
                  height: "30px",
                  width: "30px",
                  color: "#3b5998",
                }}
                icon={faFacebookSquare}
              />
            </a>
            <a
              href="https://www.instagram.com/sweet.ceces.mtjuliet/"
              target="_blank"
              style={{
                margin: "auto 2% auto 0",
                color: Theme.MAIN_COLOR,
                textDecoration: "none",
              }}
            >
              <FontAwesomeIcon
                style={{
                  height: "30px",
                  width: "30px",
                  border: css`radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)`,
                  borderRadius: 10,
                }}
                icon={faInstagram}
              />
            </a>
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
              margin: mobile ? "8vh auto auto auto" : "auto 1% auto auto",
            }}
          >
            {false &&
              routes.map((route, index) => {
                if (route.menu)
                  return (
                    <Dropdown
                      history={this.props.history}
                      label={route.label}
                      options={route.menu}
                      disableHover={initialize}
                      mobile={mobile}
                      animation={
                        initialize &&
                        css` 1s
                        ${keyframes`${fadeInDown}`}
                      `
                      }
                    ></Dropdown>
                  );
                else
                  return (
                    <NavButton
                      key={route.label + index}
                      disableHover={initialize}
                      animation={
                        initialize &&
                        css` 1s
                      ${keyframes`${fadeInDown}`}
                    `
                      }
                      onClick={() => {
                        this.setState({ open: false });
                        this.props.history.push(route.route);
                      }}
                      mobile={tiny}
                    >
                      {route.label}
                    </NavButton>
                  );
              })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(53,53,90,.0)",
            justifyContent: "flex-start",
            minHeight: "92vh",
            paddingTop: mobile ? "12vh" : "10vh",
          }}
        >
          {this.props.children}
        </div>
        <Row noWrap backgroundColor={Theme.NAV_BAR_COLOR} width="100%">
          <img
            src={process.env.REACT_APP_BRAND ? Secondary_Logo : Logo}
            style={{
              width: "auto",
              maxWidth: "95vw",
              height: "20vh",
              display: "flex",
              margin: "auto auto auto auto",
              left: "40vw",
            }}
          />
        </Row>
        <Row
          noWrap={!mobile}
          backgroundColor={Theme.NAV_BAR_COLOR}
          width="100%"
          height="auto"
        >
          <Column>
            <Row>
              <a
                href="https://www.instagram.com/sweet.ceces.mtjuliet/"
                target="_blank"
                style={{
                  margin: "auto 10% auto auto",
                  color: Theme.MAIN_COLOR,
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon
                  style={{
                    height: "60px",
                    width: "60px",
                    border: css`radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)`,
                    borderRadius: 10,
                  }}
                  icon={faInstagram}
                />
              </a>

              <a
                href="https://www.facebook.com/Sweet-Ceces-Mt-Juliet-TN-374130459801388"
                target="_blank"
                style={{ margin: "auto auto auto 0" }}
              >
                <FontAwesomeIcon
                  style={{
                    height: "60px",
                    width: "60px",
                    color: "#3b5998",
                  }}
                  icon={faFacebookSquare}
                />
              </a>
            </Row>
            <Text
              color={Theme.NAV_TEXT_COLOR}
              margin="2% auto 0 auto"
              size={mobile ? "2vw" : "1vw"}
            >
              &copy;
              {` ${moment().format(
                "YYYY"
              )} Sweet N More LLC, All rights reserved`}
            </Text>
            <Text
              color={Theme.NAV_TEXT_COLOR}
              margin="1% auto 1% auto"
              size={mobile ? "2vw" : "1vw"}
            >
              Powered By Nitely
            </Text>
          </Column>
        </Row>
      </div>
    );
  }
}

export default windowSize(NavBar);
