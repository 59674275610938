import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";
import { Image, Clickable } from "./";
import Default from "../assets/default.png";
import Cog from "../assets/cog.png";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";
import cookie from "js-cookie";

const Menu = styled.ul`
  display: ${props => (props.open ? "block" : "none")};
  flex-direction: column;
  background-color: ${Theme.BLACK};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  position: fixed;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
  list-style: none;
  padding: 1vh 0 1vh 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  z-index: 1;
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: white;
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: 100%;
  font-family: ${Theme.MAIN_FONT};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;
class ProfileButton extends Component {
  state = { open: false };
  render() {
    const { img, size, margin, history } = this.props;
    const { open } = this.state;
    return (
      <div
        style={{ margin: margin, display: "flex" }}
        onMouseLeave={() => this.setState({ open: false })}
      >
        <Clickable
          onClick={() => this.setState({ open: !open })}
          position="relative"
        >
          <Image
            src={img || Default}
            height={size || "5vw"}
            width={size || "5vw"}
            borderRadius="100%"
          />
          <Image
            position="absolute"
            right="0px"
            top="0px"
            height={"35%"}
            src={Cog}
          />
        </Clickable>
        <Menu open={open}>
          <Option onClick={() => this.props.history.push("/settings")}>
            Settings
          </Option>
          <Option
            onClick={() => {
              cookie.remove("token");
              window.location.reload();
            }}
          >
            Log Out
          </Option>
        </Menu>
      </div>
    );
  }
}
export default ProfileButton;
