const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: !process.env.REACT_APP_BRAND ? "#E6166D" : "#D77028",
  BORDER_COLOR: "#979796",
  SHADOW_COLOR: "rgba(0,0,0,1)",
  INPUT_OUTLINE_COLOR: "#979796",
  INPUT_COLOR: "rgba(255,255,255,1)",
  BUTTON_COLOR: "#B72025",
  BUTTON_HOVER_COLOR: "rgba(255,255,255,.5)",
  BORDER_COLOR: "#727272",
  NAV_TEXT_COLOR: "#2D303D",
  NAV_BAR_COLOR: "rgba(255,255,255, .9)",
  DARK_GREY: "#535354",
  LIGHT_GREY: "#79797A",
  LIGHTER_GREY: "#a39d9d",
  BLUE: "rgba(88,99,135, .80)", //"#586387",
  BLACK: "rgba(24,24,25,.95)",
  RED: "rgba(178, 34, 52,1)",
  WHITE: "rgba(255,255,255,.5)",
  BANNER_FONT: "arial",
  MAIN_FONT: "Helvetica",
  BUTTON_FONT: "sans",
};

export default theme;
